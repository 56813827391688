const extractSchemaProperties = function (props = []) {
  return `
      ${props.join("\r\n")}
    `;
};

const extractValidSchemaProperties = function (schema, props) {
  const schemaArray = Object.keys(schema);
  const propsArray = Object.keys(props);
  const validProps = schemaArray.filter((item) => propsArray.includes(item));

  let validSchema = {};
  validProps.forEach((item) => {
    validSchema[item] = props[item];
  });

  return validSchema;
};

const validateField = ({ payload, schema, schemaKey }) => {
  if (!payload[schemaKey] && !!schema[schemaKey].required)
    return `${schema[schemaKey].name || schemaKey} is required`;

  if (
    !!payload[schemaKey] &&
    !!schema[schemaKey].type &&
    typeof payload[schemaKey] !== schema[schemaKey].type
  )
    return `${schema[schemaKey].name || schemaKey} must be of type ${
      schema[schemaKey].type
    }`;
};

const validateArgumentFields = (payload, schema) => {
  /**
   * requiredFields: {
   *   key1: {
   *     name: "key1"
   *     required: true,
   *     type: "string"
   *   }
   *   key2: {
   *     name: "key2"
   *     required: false,
   *     type: "number"
   *   }
   * }
   *
   * ommit type property if you don't want to validate type
   */

  return Object.keys(schema)
    .map((schemaKey) => validateField({ payload, schema, schemaKey }))
    .filter((error) => !!error)
    .join(", ");
};

const schemaToArray = (schema) =>
  schema
    .trim()
    .split(/\n/)
    .map((field) => field.trim());

const schemaToObject = (schema) => {
  const obj = {};

  const arr = schemaToArray(schema);
  arr.forEach((a) => (obj[a] = a));

  return obj;
};

export default {
  extractSchemaProperties,
  extractValidSchemaProperties,
  validateArgumentFields,
  schemaToArray,
  schemaToObject,
};
