const currencies = {
  NGN: "NGN",
  USD: "USD",
  CNY: "CNY",
  GBP: "GBP",
  EUR: "EUR",
  CAD: "CAD",
  NG: "NGN",
  US: "USD",
  CN: "CNY",
  GB: "GBP",
  EU: "EUR",
  CA: "CAD",
};

const symbols = {
  [currencies.NGN]: "₦",
  [currencies.USD]: "$",
  [currencies.CNY]: "¥",
  [currencies.GBP]: "£",
  [currencies.EUR]: "€",
  [currencies.CAD]: "$",
};

module.exports = {
  ...currencies,
  symbols,
};
