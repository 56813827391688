const currencies = require("../data/currencies");


const currencyAmount = function (amount, { currency = currencies.NGN, display = "code" }) {
  return new Intl.NumberFormat("en", {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: display,
  }).format(amount)
};

const currencyISO2 = function (str) {
  return str.slice(0, 2);
};


export default {
  currencies,
  currencyISO2,
  currencyAmount
};