const { testRegExp } = require("./dataUtils");

function parseConfigValue(val) {
  const isObject = !![
    new RegExp(/({(.|\r|\n)*})/, "gm"),
    new RegExp(/(\[(.|\r|\n)*\])/, "gm"),
  ].find((r) => testRegExp(r, val));

  return isObject ? JSON.parse(val) : val;
}

export default {
  parseConfigValue,
};
