import { getCoreServicesState } from "../redux/store";
import {
  completeSignIn,
  completeSignUp,
  getAuthenticatedUser,
  signIn,
  signOut,
} from "./auth";
import { fetchProfile, getProfile, joinTesters } from "./users";
import {
  getUserWallet,
  hasUserWallet,
  fetchWallet,
  fetchUserWallet,
  activateUserWallet,
} from "./wallets";
import {
  isLazy as isTransactionsLazy,
  fetchWalletTransactions,
} from "./transactions";
import {
  addTimeout,
  removeTimeout,
  removeAllTimeouts,
  timeoutExists,
} from "./timeouts";
import {} from "../utils";
import {} from "../data/errors";

async function prepApp(user_id) {
  await fetchProfile({ user_id });
}

async function prepWallet(createWallet = false) {
  if (!hasUserWallet()) {
    await fetchUserWallet();
  } else {
    await fetchWallet();
  }

  if (!hasUserWallet() && createWallet) {
    await activateUserWallet();
  }

  if (hasUserWallet() && !isTransactionsLazy()) {
    const handleName = `Transactions_Init`;

    if (timeoutExists(handleName)) {
      return;
    }

    const fetchTransactions = () => {
      fetchWalletTransactions().then(() => removeTimeout(handleName));
    };

    addTimeout(handleName, fetchTransactions);
    setTimeout(fetchTransactions, 500);
  }
}

async function signInAndPrepApp(email, password) {
  const userInfo = await signIn(email, password);
  return await prepApp(userInfo.username);
}

async function refreshProfileAndPrepApp() {
  await completeSignIn(await getAuthenticatedUser());
  await prepApp(getProfile().user_id);
  await prepWallet();
}

async function completeSignUpAndJoinTesters(email, code) {
  const result = await completeSignUp(email, code);
  try {
    await joinTesters(email);
  } catch (e) {
    console.log(e);
  }
  return result;
}

async function signOutAndCleanApp() {
  await signOut();
  removeAllTimeouts();
}

export {
  completeSignUpAndJoinTesters,
  refreshProfileAndPrepApp,
  signInAndPrepApp,
  prepWallet,
  signOutAndCleanApp,
};
