function formatAmount(number) {
  return new Intl.NumberFormat("en", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

const numberCharacter = (number = "") => {
  return number.replace(/[^0-9.]/g, "");
};

const digitCharacter = (number = "") => {
  return number.replace(/[^0-9]/g, "");
};

const toDecimalNumber = (number, decimals) => {
  return new RegExp(/-?\d+\.{1}\d+/).test(number)
    ? number
    : Number(number).toFixed(decimals || 2);
};

const prettyNumber = (number, { minDecimal = 0, noDecimal, maxDecimal = 2 }) =>
  new Intl.NumberFormat("en", {
    style: "decimal",
    minimumFractionDigits: noDecimal ? 0 : minDecimal,
    maximumFractionDigits: noDecimal ? 0 : maxDecimal,
  }).format(number);

const toProgressBar = (numerator, denominator, decimals = 2) => {
  const val =
    (!Number(denominator) && Number(numerator)) ||
    Number(numerator) > Number(denominator)
      ? 1
      : Number(denominator)
      ? Number(numerator) / Number(denominator)
      : 0;
  return +val.toFixed(decimals);
};

const toPercentage = (numerator, denominator) =>
  toProgressBar(numerator, denominator, 3) * 100;

export default {
  formatAmount,
  digitCharacter,
  numberCharacter,
  prettyNumber,
  toDecimalNumber,
  toProgressBar,
  toPercentage,
};
