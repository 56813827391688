//
// Initial State...
//

const initialState = {
  // System
  appState: "foreground",
  privacyMode: false,
  network: {},
  timeouts: {},

  //Auth
  user: {},
  userChallenge: {},

  // Services
  wallet: {},
  transactions: [],

  //subscription Watchers: must follow pattern /.*Watchers/
};

export default initialState;
