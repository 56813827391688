import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  idType: "",
};

export const IdentityVerificationSlice = createSlice({
  name: "identityVerification",
  initialState,
  reducers: {
    SelectIdType: (state, action) => {
      state.idType = action.payload;
    },
  },
});

export const { SelectIdType } = IdentityVerificationSlice.actions;

export default IdentityVerificationSlice.reducer;
