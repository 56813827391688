import {
  addTimeout,
  getTimeouts,
  replaceTimeout,
  timeoutExists,
} from "./timeouts";

async function serverFetchAll(fn, args) {
  let allItems = [];
  let { items, nextToken } = await fn(args);
  allItems = allItems.concat(items);

  while (!!nextToken) {
    const res = await fn(args, nextToken);

    nextToken = res.nextToken;
    allItems = allItems.concat(res.items);
  }
  return allItems;
}

function lazyloadService({
  handle,
  delay = 300000,
  fn,
  errorHandler,
  successHandler,
  stopOnError = () => false,
  stopOnSuccess = () => false,
  runAtOnce = true,
}) {
  if (!handle || !fn || !errorHandler || !successHandler) {
    throw "Invalid configuration";
  }

  const hasTimeout = timeoutExists(handle);
  const createTimeout = hasTimeout ? replaceTimeout : addTimeout;

  const lazyLoader = () => {
    const timeout = getTimeouts()[handle];

    if (!!timeout && new Date().getTime() - timeout.created < delay / 2) {
      return;
    }

    fn()
      .then((res) => {
        successHandler(res);
        if (!stopOnSuccess()) {
          createTimeout(handle, lazyLoader);
          setTimeout(lazyLoader, delay);
        }
      })
      .catch((err) => {
        if (!stopOnError()) {
          createTimeout(handle, lazyLoader);
          setTimeout(lazyLoader, delay);
        }
        errorHandler(err);
      });
  };

  if (runAtOnce) {
    lazyLoader();
  } else {
    setTimeout(lazyLoader, delay);
  }
}

export { lazyloadService, serverFetchAll };
