import { graphqlOperation } from "@aws-amplify/api";
import { store } from "../redux";
import { getCoreServicesState } from "../redux/store";
import { setUserProfile } from "../redux/slice";
import { Users } from "../graphql/schemas";
import { getUser } from "../graphql/queries";
import { joinTestUsers } from "../graphql/mutations";
import { callUserApiFunction } from "./amplify";
import { cleanForDBPush, extractApiError } from "./helpers";
import {} from "../data/errors";
import fetchUtis from "../utils/fetchUtis";
import schemaUtils from "../utils/schemaUtils";
import { API_ENDPOINT } from "../config";

const LIB_SCHEMA = schemaUtils.schemaToObject(Users);

const prepForRedux = (user) =>
  cleanForDBPush(schemaUtils.extractValidSchemaProperties(LIB_SCHEMA, user));

async function serverFetch(user) {
  const fetchedUser = await callUserApiFunction(
    "graphql",
    graphqlOperation(getUser, {
      user_id: user.user_id,
    })
  );

  const fUser = fetchedUser.data.getUser;

  const preppedUser = prepForRedux(fUser);
  store.dispatch(setUserProfile(preppedUser));

  return preppedUser;
}

const fetchProfile = async function (user) {
  const userTarget = user ? user : getProfile();
  try {
    await serverFetch(userTarget);
  } catch (e) {
    throw extractApiError(e);
  }
};

const joinTesters = async function (email) {
  if (isUserSignedIn()) {
    try {
      return await callUserApiFunction(
        "graphql",
        graphqlOperation(joinTestUsers, {
          joinTestersInput: { email: email || getProfile().email },
        })
      );
    } catch (e) {
      throw extractApiError(e);
    }
  } else {
    const URL = `${API_ENDPOINT}/joinTesters`;

    try {
      await fetchUtis.fetchResource(URL, {
        method: "post",
        customOptions: {
          mode: "no-cors",
        },
        body: {
          input: {
            email,
          },
        },
      });
    } catch (e) {
      console.log("API_ENDPOINT-Error", e);
      throw e;
    }
  }
};

//Local Getters
const getProfile = function () {
  return getCoreServicesState().user;
};

const isUserSignedIn = function () {
  const user = getProfile();
  if (user && user.user_id) {
    return true;
  }

  return false;
};

const isTestingUser = function () {
  const user = getProfile();
  return !!(user && user.testing_requested && user.is_testing);
};

const isTestingRequestedUser = function () {
  const user = getProfile();
  return !!(user && user.testing_requested);
};

export {
  fetchProfile,
  joinTesters,
  getProfile,
  isUserSignedIn,
  isTestingUser,
  isTestingRequestedUser,
};
