import {
  Rates,
  Users,
  Wallets,
  Transactions,
  FullTransactions,
  FundOptions,
  FundDetails,
} from "./schemas";

const getRates = `query getRates($from: String!,$to: String!,$amount: String!){
    getRates (from: $from, to: $to, amount: $amount) {
        ${Rates}
    }
}`;

const getUser = `query getUser($user_id: String!){
    getUser (user_id: $user_id) {
        ${Users}
    }
}`;

const getWallet = `query getWallet($wallet_id: String!){
    getWallet (wallet_id: $wallet_id) {
        ${Wallets}
    }
}`;

const getTransaction = `query getTransaction($transaction_id: String!){
    getTransaction (transaction_id: $transaction_id) {
        ${Transactions}
    }
}`;

const getFundOptions = `query getFundOptions($currency: String!){
    getFundOptions (currency: $currency) {
        ${FundOptions}
    }
}`;

const getFundDetails = `query getFundDetails($currency: String!, $option: String!){
    getFundDetails (currency: $currency, option: $option) {
        ${FundDetails}
    }
}`;

const listUsers = `query listUsers($userFilter: UserFilterInput!){
    listUsers (filter: $userFilter) {
        items{
            ${Users}
        }
        nextToken
    }
}`;

const listWallets = `query listWallets($walletFilter: UserFilterInput!){
    listWallets (filter: $walletFilter) {
        items{
            ${Wallets}
        }
        nextToken
    }
}`;

const listTransactions = `query listTransactions($transactionFilter: TransactionFilterInput!){
    listTransactions (filter: $transactionFilter) {
        items{
            ${FullTransactions}
        }
        nextToken
    }
}`;

export {
  getRates,
  getUser,
  getWallet,
  getTransaction,
  getFundOptions,
  getFundDetails,
  listUsers,
  listWallets,
  listTransactions,
};
