import { errorUtils } from "../utils";

let AWSEXPORTS, AWSMANUALEXPORTS, store, API_ENDPOINT;

const configNames = {
  AWSEXPORTS: "AWSEXPORTS",
  AWSMANUALEXPORTS: "AWSMANUALEXPORTS",
  store: "store",
  API_ENDPOINT: "API_ENDPOINT",
};

const setConfig = (name, value) => {
  if (!name || configNames[name] !== name) {
    throw errorUtils.newError("Invalid configuration");
  }

  switch (name) {
    case configNames.AWSEXPORTS:
      AWSEXPORTS = value;
      break;
    case configNames.AWSMANUALEXPORTS:
      AWSMANUALEXPORTS = value;
      break;
    case configNames.store:
      store = value;
      break;
    case configNames.API_ENDPOINT:
      API_ENDPOINT = value;
      break;
  }
};

export { AWSEXPORTS, AWSMANUALEXPORTS, store, API_ENDPOINT, setConfig };
