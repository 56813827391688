import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isSignedIn } from "client-core-services/services/auth";
import { refreshProfileAndPrepApp } from "client-core-services/services/app";

const useAuth = () => {
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await isSignedIn();
        if (!!res) {
          await refreshProfileAndPrepApp();
        }
        setIsAuth(res);
      } catch (e) {
        console.log(e);
        setIsAuth(false);
      }
    };

    fetchData();
  }, []);

  return isAuth;
};

const ProtectedRoute = ({ children }) => {
  const isAuth = useAuth();

  if (isAuth === null)
    // waiting..
    return null;

  return isAuth ? children : <Navigate to="/auth/loginFormik" />;
};

export default ProtectedRoute;
