import { AWSEXPORTS, AWSMANUALEXPORTS } from "../config";
import { isSignedIn } from "./auth";

let Amplify, Auth, Api, PubSub, Storage;

const getEnvConfig = () => ({
  ...(typeof AWSEXPORTS === "object" ? AWSEXPORTS : JSON.parse(AWSEXPORTS)),
  ...(typeof AWSMANUALEXPORTS === "object"
    ? AWSMANUALEXPORTS
    : JSON.parse(AWSMANUALEXPORTS)),
});

const config = ({
  aws_appsync_authenticationType,
  graphql_headers,
  Auth,
  Storage,
} = {}) => {
  const configs = {
    ...getEnvConfig(),
    ...(aws_appsync_authenticationType
      ? { aws_appsync_authenticationType }
      : {}),
    ...(Auth ? { Auth } : {}),
    ...(Storage ? { Storage } : {}),
    ...{
      graphql_headers: async () => {
        let headers = {};

        try {
          const token = (await callAuthFunction("currentSession")).idToken
            .jwtToken;
          headers = { ...graphql_headers, Authorization: token };
        } catch (e) {
          console.error(e);
          headers = { ...graphql_headers };
          // Potentially you can retrieve it from local storage
        }

        return headers;
      },
    },
  };

  return configs;
};

const fetchAmplifyComponent = function (type) {
  const awsAmplify = require("aws-amplify");
  switch (type) {
    case "Auth":
      if (!Auth) {
        Auth = awsAmplify.Auth;
      }
      return Auth;
    case "Api":
      if (!Api) {
        Api = awsAmplify.API;
      }
      return Api;
    case "Amplify":
      if (!Amplify) {
        Amplify = awsAmplify.default;
      }
      return Amplify;
    case "PubSub":
      if (!PubSub) {
        PubSub = awsAmplify.PubSub;
      }
      return PubSub;
    case "Storage":
      Storage = awsAmplify.Storage;
      return Storage;
    default:
      return undefined;
  }
};

const supportedConfig = [
  "aws_appsync_authenticationType",
  "graphql_headers",
  "Storage",
  "Auth",
];

const extractCallArgs = function (...args) {
  const lastArg = args[Object.keys(args).length - 1];
  const hasConfig =
    Object.keys(args).length > 0 &&
    typeof lastArg === "object" &&
    Object.keys(lastArg).some((k) => supportedConfig.includes(k));

  return {
    callArgs: !hasConfig ? args : args.slice(0, args.length - 1),
    callConfig: !hasConfig ? undefined : lastArg,
  };
};

const generateRequestConfig = function (extraConfig = {}) {
  const customConfig = supportedConfig.reduce((all, each) => {
    if (extraConfig[each]) {
      all[each] = extraConfig[each];
    }

    return all;
  }, {});

  const finalConfig = config(customConfig);
  return finalConfig;
};

const configureAmplify = function (extraConfig) {
  fetchAmplifyComponent("Amplify").configure(
    generateRequestConfig(extraConfig)
  );
};

const configureAmplifyAuth = function (extraConfig) {
  configureAmplify(extraConfig);
  fetchAmplifyComponent("Auth").configure();
};

const configureAmplifyApi = function (extraConfig) {
  configureAmplifyAuth(extraConfig);
  fetchAmplifyComponent("Api").configure();
};

const configureAmplifyPubSub = function (extraConfig) {
  configureAmplifyAuth(extraConfig);
  fetchAmplifyComponent("PubSub").configure();
};

const configureAmplifyStorage = function (extraConfig) {
  configureAmplifyAuth(extraConfig);
  fetchAmplifyComponent("Storage").configure();
};

const callAuthFunction = function (functionName, ...args) {
  configureAmplifyAuth();
  return fetchAmplifyComponent("Auth")[functionName](...args);
};

const callApiFunction = function (functionName, ...args) {
  const { callArgs, callConfig } = extractCallArgs(...args);
  configureAmplifyApi(callConfig);

  return fetchAmplifyComponent("Api")[functionName](...callArgs);
};

const callUserApiFunction = async function (...args) {
  if (await isSignedIn()) {
    return callApiFunction(...args);
  }
};

const callPubSubFunction = function (functionName, ...args) {
  const { callArgs, callConfig } = extractCallArgs(...args);
  configureAmplifyPubSub(callConfig);

  return fetchAmplifyComponent("PubSub")[functionName](...callArgs);
};

const callStorageFunction = function (functionName, ...args) {
  const { callArgs, callConfig } = extractCallArgs(...args);
  configureAmplifyStorage(callConfig);

  return fetchAmplifyComponent("Storage")[functionName](...callArgs);
};

const loadUpAmplify = function () {
  configureAmplify();
  configureAmplifyPubSub();
};

const getAmplifyLibs = () => ({
  Amplify,
  Auth,
  Api,
  PubSub,
  Storage,
});

export {
  callAuthFunction,
  callApiFunction,
  callUserApiFunction,
  callPubSubFunction,
  callStorageFunction,
  loadUpAmplify,
  getAmplifyLibs,
  getEnvConfig,
};
