import initialState from "./initialState";
//import Types from "./types";

const reducers = {
  //AppState
  setAppState: (state = initialState, action) => {
    state.appState = action.payload;
  },

  //Network
  setConnectionQueue: (state = initialState, action) => {
    state.network = { ...state.network, onConnectQueue: action.payload };
  },

  // Timeouts
  addTimeout: (state = initialState, action) => {
    state.timeouts = { ...state.timeouts, ...action.payload };
  },
  pollTimeouts: (state = initialState, action) => {
    state.timeouts = action.payload;
  },

  //Auth
  login: (state, action) => {
    state = {
      ...initialState,
      user: action.payload,
      privacyMode: state.privacyMode,
    };
    return state;
  },
  logout: (state = initialState) => {
    state = {
      ...initialState,
      user: { email: (state.user && state.user.email) || "" },
      privacyMode: state.privacyMode,
    };
    return state;
  },
  setUserChallenge: (state = initialState, action) => {
    state.userChallenge = action.payload;
  },
  setUserEmail: (state, action) => {
    state.user = {
      ...state.user,
      email: action.payload,
    };
  },
  setUserProfile: (state, action) => {
    state.user = {
      ...state.user,
      ...action.payload,
    };
  },

  // Wallet
  setWallet: (state, action) => {
    state.wallet = action.payload;
  },

  // Transactions
  setTransactions: (state, action) => {
    state.transactions = action.payload;
  },
};

export default reducers;
