function cleanUpStrings(string, allowNull) {
  return allowNull
    ? string === null
      ? null
      : string.trim()
    : string
    ? string.trim()
    : "";
}

function cleanUpObjects(obj, allowNull) {
  const cleaned = {};
  for (const key in obj) {
    if (typeof obj[key] === "boolean") cleaned[key] = obj[key];
    if (
      (obj[key] !== null && typeof obj[key] !== "undefined") ||
      (allowNull && obj[key] === null)
    ) {
      cleaned[key] = obj[key];
    }
  }
  return cleaned;
}

function cleanForDBPush(value, allowNull = false) {
  if (typeof value === "string") {
    return cleanUpStrings(value, allowNull);
  } else {
    return cleanUpObjects(value, allowNull);
  }
}

function promisifyFunctionCallback(func, ...params) {
  return new Promise((resolve, reject) => {
    try {
      func(...params, function (err, data) {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    } catch (e) {
      return reject(e);
    }
  });
}

const extractApiError = function (error) {
  return typeof error === "object"
    ? error.message ||
        (error.errors && error.errors[0] && error.errors[0].message) ||
        (error.error.errors &&
          error.error.errors[0] &&
          error.error.errors[0].message)
    : error;
};

export {
  cleanForDBPush,
  cleanUpObjects,
  cleanUpStrings,
  extractApiError,
  promisifyFunctionCallback,
};
