import { createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
//import types from "./types";
import reducers from "./reducers";

export const CoreServicesSlice = createSlice({
  name: "coreServices",
  initialState,
  reducers,
});

export const {
  setAppState,
  setConnectionQueue,
  addTimeout,
  pollTimeouts,
  login,
  logout,
  setUserChallenge,
  setUserProfile,
  setUserEmail,
  setWallet,
  setTransactions,
} = CoreServicesSlice.actions;

export default CoreServicesSlice.reducer;
