const { parseConfigValue } = require("./configUtils");

const METHODS = {
  get: "get",
  post: "post",
  patch: "patch",
  put: "put",
};

// eslint-disable-next-line
const REQUEST_BODY_TYPE = {
  json: "json",
  form: "form",
  file: "file",
};

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const extractResponseBody = async function (response) {
  const responseContent = await response;
  const contentType = response.headers["content-type"];
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return await responseContent.json();
  } else {
    return await response.text();
  }
};

const fetchResource = function (
  url,
  { method = "get", body, headers, authorization, customOptions = {} }
) {
  const args = {
    method: METHODS[method.toLowerCase()],
    headers: headers || DEFAULT_HEADERS,
  };

  if (typeof body !== "undefined") {
    args.body = typeof body !== "string" ? JSON.stringify(body) : body;
  }

  authorization = authorization || {};
  const { username, password } = authorization;

  if (typeof username !== "undefined" || typeof password !== "undefined") {
    args.auth = { username, password };
  }

  return new Promise((res, rej) =>
    fetch(url, { ...args, ...customOptions })
      .then(res)
      .catch(rej)
  );
};

module.exports = {
  extractResponseBody,
  fetchResource,
  DEFAULT_HEADERS,
};
