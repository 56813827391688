const trimString = (string, chars) =>
  chars ? string.substring(0, chars) : string;
const isUrlString = (string) => new RegExp(/[./].+[./]?/).test(string);
const capitalize = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
const camelCase = (string) =>
  string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[\s-]+/g, "");

export default {
  capitalize,
  isUrlString,
  trimString,
  camelCase,
};
