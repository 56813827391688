import {
  addTimeout as reduxAddTimeout,
  pollTimeouts as reduxPollTimeouts,
} from "../redux/slice";
import { store } from "../config";
import { getCoreServicesState } from "../redux/store";

const getTimeouts = function () {
  const { timeouts } = getCoreServicesState();
  return timeouts;
};

const timeoutExists = function (handle) {
  const timeouts = getTimeouts();

  return !!Object.keys(timeouts).find((x) => x == handle);
};

const addTimeout = function (handle, fn) {
  store.dispatch(
    reduxAddTimeout({ [handle]: { fn, created: new Date().getTime() } })
  );
};

const removeTimeout = function (handle) {
  if (!timeoutExists(handle)) {
    return;
  }

  const timeouts = getTimeouts();

  if (Object.keys(timeouts).length == 1) {
    return removeAllTimeouts();
  }

  clearTimeout(timeouts[handle].fn);
  delete timeouts[handle];

  store.dispatch(reduxPollTimeouts(timeouts));
};

const replaceTimeout = function (handle, fn) {
  removeTimeout(handle);
  addTimeout(handle, fn);
};

const removeAllTimeouts = function () {
  const timeouts = getTimeouts();
  Object.keys(timeouts).map((t) => clearTimeout(timeouts[t].fn));

  store.dispatch(reduxPollTimeouts({}));
};

export {
  addTimeout,
  getTimeouts,
  removeTimeout,
  replaceTimeout,
  removeAllTimeouts,
  timeoutExists,
};
