const Rates = `
    rate
    fee
    finalAmount
    currency
    amount
`;

const Users = `
    user_id
    country
    created
    phone
    is_testing
    testing_requested
`;

const Wallets = `
    wallet_id
    user_id
    balance
    created
`;

const Transactions = `
    transaction_id
    sender_wallet_id
    recipient_wallet_id
    amount
    created
    status
    status_message
    type
    updated
    recipient
    sender
`;

const TransactionsParticipants = `
    account
    instituition
    details
`;

const FullTransactions = `
    transaction_id
    sender_wallet_id
    recipient_wallet_id
    amount
    created
    status
    status_message
    type
    updated
    recipient {
        ${TransactionsParticipants}
    }
    sender {
        ${TransactionsParticipants}
    }
`;

const FundOption = `
    name
    currency
`;

const FundOptions = `
    items {
        ${FundOption}
    }
`;

const FundDetails = `
    name
    currency
    details
`;

const Result = `
    status
`;

export {
  Result,
  Rates,
  Users,
  Wallets,
  Transactions,
  TransactionsParticipants,
  FullTransactions,
  FundOptions,
  FundDetails,
};
