const dataUtils = {
  toShortHand: (amt) => {
    const fraction = amt / 1000000 >= 1 ? "m" : amt / 1000 >= 1 ? "k" : "";
    const val =
      fraction === "m" ? amt / 1000000 : fraction === "k" ? amt / 1000 : amt;
    return `${
      new RegExp(/-?\d+\.{1}\d+/).test(val) ? val.toFixed(1) : val || 0
    }${fraction}`;
  },
  getUniqueValues: (values = []) => [...new Set(values)],
  updateInList: (list, id, value) => {
    list[id] = value;
    return list;
  },
  removeFromList: (list, id) => {
    const items = list;
    items.splice(id, 1);
    return items;
  },
};

const extractProperties = function (raw, props = [], allowUndefined = true) {
  const cleaned = {};
  props.forEach((prop) => {
    if (allowUndefined || typeof raw[prop] !== "undefined") {
      cleaned[prop] = raw[prop];
    }
  });

  return cleaned;
};

export default {
  ...dataUtils,
  extractProperties,
};
