import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import CoreServicesReducer from "client-core-services/redux/slice";
import NotesReducer from "./apps/notes/NotesSlice";
import CustomizerReducer from "./customizer/CustomizerSlice";
import ChatsReducer from "./apps/chat/ChatSlice";
import ContactsReducer from "./apps/contacts/ContactSlice";
import EmailReducer from "./apps/email/EmailSlice";
import TicketReducer from "./apps/ticket/TicketSlice";
import IdentityVerificationReducer from "./apps/kyc/IdentityVerificationSlice";

export const store = configureStore({
  reducer: {
    coreServices: CoreServicesReducer,
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    chatReducer: ChatsReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    ticketReducer: TicketReducer,
    IdentityVerification: IdentityVerificationReducer,
  },
  middleware: [thunk],
});

export default store;
