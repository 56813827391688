const CHALLENGES = {
  resetPassword: "NEW_PASSWORD_REQUIRED",
  tokenMFA: "SOFTWARE_TOKEN_MFA",
};

const invalidCodeError = (error) => {
  const InvalidCodeErrors = ["code mismatch", "invalid code"];
  if (
    !!InvalidCodeErrors.find((invalid) => error.toLowerCase().includes(invalid))
  ) {
    return "Invalid code";
  }
  return error;
};

export default {
  CHALLENGES,
  invalidCodeError,
};
