import { graphqlOperation } from "@aws-amplify/api";
import { store } from "../redux";
import { getCoreServicesState } from "../redux/store";
import { setWallet } from "../redux/slice";
import { Wallets } from "../graphql/schemas";
import { getWallet, listWallets } from "../graphql/queries";
import { instantiateWallet } from "../graphql/mutations";
import { callUserApiFunction } from "./amplify";
import { cleanForDBPush, extractApiError } from "./helpers";
import { getProfile } from "./users";
import { timeoutExists } from "./timeouts";
import {} from "../data/errors";
import schemaUtils from "../utils/schemaUtils";
import { lazyloadService, serverFetchAll } from "./utils";

const LazyLoadHandle = "WalletLazyLoader";
const LIB_SCHEMA = schemaUtils.schemaToObject(Wallets);

const isLazy = () => timeoutExists(LazyLoadHandle);

const serverFetchAllWallets = async (filter, nextToken) => {
  const fetchedWallet = await callUserApiFunction(
    "graphql",
    graphqlOperation(listWallets, {
      ...filter,
      nextToken,
    })
  );

  const fWallets = fetchedWallet.data.listWallets.items;
  const preppedWallet = fWallets.map((fWallet) =>
    cleanForDBPush(
      schemaUtils.extractValidSchemaProperties(LIB_SCHEMA, fWallet)
    )
  );

  return {
    items: preppedWallet,
    nextToken: fetchedWallet.data.listWallets.nextToken,
  };
};

async function serverFetch(wallet) {
  const fetchedWallet = await callUserApiFunction(
    "graphql",
    graphqlOperation(getWallet, {
      wallet_id: wallet.wallet_id,
    })
  );

  const fWallet = fetchedWallet.data.getWallet;
  const preppedWallet = cleanForDBPush(
    schemaUtils.extractValidSchemaProperties(LIB_SCHEMA, fWallet)
  );

  store.dispatch(setWallet(preppedWallet));
  return preppedWallet;
}

const lazyFetchWallet = function (wallet) {
  lazyloadService({
    handle: LazyLoadHandle,
    delay: 60000,
    runAtOnce: false,
    fn: () => serverFetch(wallet),
    errorHandler: (e) => console.log(extractApiError(e)),
    successHandler: (res) => {},
    stopOnError: (error) => !hasUserWallet(),
    stopOnSuccess: (res) => !hasUserWallet(),
  });
};

const fetchWallet = async function (wallet) {
  const walletTarget = wallet ? wallet : getUserWallet();
  try {
    await serverFetch(walletTarget);
  } catch (e) {
    throw extractApiError(e);
  }
};

const fetchUserWallet = async function () {
  const user = getProfile();

  try {
    const wallets = await serverFetchAll(serverFetchAllWallets, {
      walletFilter: {
        user_id: user.user_id,
      },
    });

    if (wallets[0]) {
      store.dispatch(setWallet(wallets[0]));
      if (!isLazy()) {
        lazyFetchWallet(wallets[0]);
      }
    }
  } catch (e) {
    throw extractApiError(e);
  }
};

const activateUserWallet = async function () {
  const user = getProfile();
  try {
    const userWallet = await callUserApiFunction(
      "graphql",
      graphqlOperation(instantiateWallet, {
        instantiateWalletInput: { user_id: user.user_id },
      })
    );

    const fWallet = userWallet.data.instantiateWallet;
    if (fWallet) {
      store.dispatch(setWallet(fWallet));
    }

    return fWallet;
  } catch (e) {
    throw extractApiError(e);
  }
};

//Local Getters
const getUserWallet = function () {
  return getCoreServicesState().wallet;
};

const hasUserWallet = function () {
  const wallet = getUserWallet();
  return !!(wallet && wallet.wallet_id);
};

export {
  isLazy,
  getUserWallet,
  hasUserWallet,
  fetchWallet,
  fetchUserWallet,
  activateUserWallet,
};
