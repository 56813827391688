import services from "./services";
import utils from "./utils";
import { setConfig } from "./config";
import { loadUpAmplify } from "./services/amplify";

const configure = (...config) => {
  const [name, value] = config;

  if (typeof name === "string") {
    setConfig(name, value);
  } else {
    if (typeof name === "object") {
      Object.keys(name).map((key) => setConfig(key, name[key]));
    }
  }
  loadUpAmplify();
};

const coreServices = { services, utils };

export { coreServices as default, configure, services, utils };
