// https://aws-amplify.github.io/docs/js/react
import { callAuthFunction } from "./amplify";
import {
  login,
  logout,
  setUserProfile,
  setUserEmail,
  setUserChallenge as coreSetUserChallenge,
} from "../redux/slice";
import { cleanForDBPush } from "./helpers";
import { authUtils, errorUtils } from "../utils";
import { store } from "../config";
import { getCoreServicesState } from "../redux/store";

const getAuthenticatedUser = async function () {
  return await callAuthFunction("currentAuthenticatedUser");
};

const getCurrentUserInfo = async function () {
  return await callAuthFunction("currentUserInfo");
};

const setUserChallenge = async function (challenge) {
  if (challenge) {
    store.dispatch(coreSetUserChallenge(challenge));
  }
};

const isSignedIn = async function () {
  try {
    const authenticatedUser = await getAuthenticatedUser();

    return !!authenticatedUser;
  } catch (e) {
    return false;
  }
};

const signUp = async function (email, password, firstName, lastName, country) {
  if (!country || !lastName || !firstName || !email || !password) {
    throw "Incorrect signup details";
  }
  try {
    const signUpParams = cleanForDBPush({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLowerCase().trim(),
        family_name: lastName,
        given_name: firstName,
        "custom:country": country,
      },
    });
    return await callAuthFunction("signUp", signUpParams);
  } catch (e) {
    throw e.message || e;
  }
};

const completeSignUp = async function (email, code) {
  try {
    return await callAuthFunction(
      "confirmSignUp",
      email.toLowerCase().trim(),
      code
    );
  } catch (e) {
    throw e.message || e;
  }
};

// resend code
const resendSignUpCode = async function (email) {
  try {
    return await callAuthFunction("resendSignUp", email.toLowerCase().trim());
  } catch (e) {
    throw e.message || e;
  }
};

const completeSignIn = async function (signedIn) {
  const user = await getCurrentUserInfo();

  store.dispatch(
    login({
      ...getCoreServicesState().user,
      ...(user.attributes || {}),
      user_id: user.username,
    })
  );

  return user;
};

const signIn = async function (email, password) {
  let signedIn;

  if (!email || !password) {
    throw "Incorrect login details";
  }

  try {
    const signInParams = [email.toLowerCase().trim(), password];
    signedIn = await callAuthFunction("signIn", ...signInParams);

    store.dispatch(
      login({
        ...getCoreServicesState().user,
        ...(signedIn.attributes || {}),
        email: email.toLowerCase().trim(),
      })
    );

    if (signedIn.challengeName) {
      setUserChallenge(signedIn);
      if (signedIn.challengeName === authUtils.CHALLENGES.resetPassword) {
        throw `Password reset required for the user : no MFA`;
      } else if (signedIn.challengeName === authUtils.CHALLENGES.tokenMFA) {
        throw `2FA token required for the user : Token MFA`;
      }
    }

    return await completeSignIn(signedIn);
  } catch (e) {
    throw e.message || e;
  }
};

const signOut = async function () {
  store.dispatch(logout());

  try {
    return await callAuthFunction("signOut");
  } catch (e) {
    if (!new RegExp("No current user", "ig").test((e && e.message) || e)) {
      throw e.message || e;
    }
  }
};

const signOutThenIn = async (...args) => {
  await signOut();
  return signIn(...args);
};

const forgotPassword = async function (email) {
  if (!email) {
    throw "Incorrect email details";
  }
  try {
    const forgotPasswordParams = email.toLowerCase().trim();

    const forgotPass = await callAuthFunction(
      "forgotPassword",
      forgotPasswordParams
    );
    store.dispatch(setUserEmail(forgotPasswordParams));

    return forgotPass;
  } catch (e) {
    throw e.message || e;
  }
};

const forgotPasswordSubmit = async function (email, code, password) {
  try {
    return await callAuthFunction(
      "forgotPasswordSubmit",
      email.toLowerCase(),
      code,
      password
    );
  } catch (e) {
    throw e.message || e;
  }
};

const completeNewPasswordSubmit = async function (password) {
  try {
    const complete = await callAuthFunction(
      "completeNewPassword",
      getCoreServicesState().userChallenge,
      password
    );
    setUserChallenge({});

    return complete;
  } catch (e) {
    throw e.message || e;
  }
};

const resetPassword = async function (oldPassword, password) {
  try {
    const user = await getAuthenticatedUser();
    const complete = await callAuthFunction(
      "changePassword",
      user,
      oldPassword,
      password
    );

    return complete;
  } catch (e) {
    throw e.message || e;
  }
};

export {
  getAuthenticatedUser,
  isSignedIn,
  signUp,
  completeSignUp,
  resendSignUpCode,
  signIn,
  signOut,
  signOutThenIn,
  completeSignIn,
  forgotPassword,
  forgotPasswordSubmit,
  completeNewPasswordSubmit,
  resetPassword,
};
