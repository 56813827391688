import { graphqlOperation } from "@aws-amplify/api";
import { store } from "../redux";
import { getCoreServicesState } from "../redux/store";
import { setTransactions } from "../redux/slice";
import { Transactions } from "../graphql/schemas";
import {
  getTransaction,
  getFundOptions,
  getFundDetails,
  listTransactions,
} from "../graphql/queries";
import { callUserApiFunction } from "./amplify";
import { cleanForDBPush, extractApiError } from "./helpers";
import { getUserWallet, hasUserWallet } from "./wallets";
import {} from "../data/errors";
import schemaUtils from "../utils/schemaUtils";
import { lazyloadService, serverFetchAll } from "./utils";
import { timeoutExists } from "./timeouts";

const LazyLoadHandle = "TransactionsLazyLoader";
const LIB_SCHEMA = schemaUtils.schemaToObject(Transactions);

const isLazy = () => timeoutExists(LazyLoadHandle);

const serverFetchAllTrasactions = async (filter, nextToken) => {
  const fetchedTransaction = await callUserApiFunction(
    "graphql",
    graphqlOperation(listTransactions, {
      ...filter,
      nextToken,
    })
  );

  const fTransactions = fetchedTransaction.data.listTransactions.items;
  const preppedTransactions = fTransactions.map((fTransaction) =>
    cleanForDBPush(
      schemaUtils.extractValidSchemaProperties(LIB_SCHEMA, fTransaction)
    )
  );

  return {
    items: preppedTransactions,
    nextToken: fetchedTransaction.data.listTransactions.nextToken,
  };
};

async function serverFetch(transaction) {
  const fetchedTransaction = await callUserApiFunction(
    "graphql",
    graphqlOperation(getTransaction, {
      transaction_id: transaction.transaction_id,
    })
  );

  const fTransaction = fetchedTransaction.data.getTransaction;
  const preppedTransactions = cleanForDBPush(
    schemaUtils.extractValidSchemaProperties(LIB_SCHEMA, fTransaction)
  );

  return preppedTransactions;
}

async function serverFetchandStore(wallet) {
  const transactions = await serverFetchAll(serverFetchAllTrasactions, {
    transactionFilter: {
      sender_wallet_id: { eq: wallet.wallet_id },
      recipient_wallet_id: { eq: wallet.wallet_id },
    },
  });

  if (transactions && transactions.length > 0) {
    store.dispatch(setTransactions(transactions));
  }
}

const lazyFetchTranactions = function (wallet) {
  lazyloadService({
    handle: LazyLoadHandle,
    delay: 300000,
    runAtOnce: false,
    fn: () => serverFetchandStore(wallet),
    errorHandler: (e) => console.log(extractApiError(e)),
    successHandler: (res) => {},
    // stopOnError: (error) => true,
  });
};

const fetchTransaction = async function (transaction) {
  if (!transaction) {
    throw "Invalid transaction";
  }
  try {
    await serverFetch(transaction);
  } catch (e) {
    throw extractApiError(e);
  }
};

const fetchWalletTransactions = async function () {
  if (!hasUserWallet()) {
    return;
  }

  const wallet = getUserWallet();

  try {
    serverFetchandStore(wallet);
    if (!isLazy()) {
      lazyFetchTranactions(wallet);
    }
  } catch (e) {
    throw extractApiError(e);
  }
};

const fetchFundOptions = async function (currency) {
  const fetchedOptions = await callUserApiFunction(
    "graphql",
    graphqlOperation(getFundOptions, {
      currency,
    })
  );

  return fetchedOptions.data.getFundOptions.items;
};

const fetchFundDetails = async function (currency, option) {
  const fetchedDetails = await callUserApiFunction(
    "graphql",
    graphqlOperation(getFundDetails, {
      currency,
      option,
    })
  );

  const details = fetchedDetails.data.getFundDetails;
  return {
    ...details,
    details:
      typeof details.details === "string" &&
      ["{", "["].some((ch) => details.details.includes(ch))
        ? JSON.parse(details.details)
        : details.details,
  };
};

//Local Getters
const getUserTransactions = function () {
  return getCoreServicesState().transactions;
};

const hasUserTransactions = function () {
  const transactions = getUserTransactions();
  return !!(transactions && transactions.length > 0);
};

const isUserTransaction = function (transaction, incoming) {
  const wallet = getCoreServicesState().wallet;

  return typeof incoming === "undefined"
    ? [transaction.sender_wallet_id, transaction.recipient_wallet_id].includes(
        wallet.wallet_id
      )
    : !!incoming
    ? transaction.recipient_wallet_id === wallet.wallet_id
    : !incoming
    ? transaction.sender_wallet_id === wallet.wallet_id
    : false;
};

export {
  isLazy,
  getUserTransactions,
  hasUserTransactions,
  isUserTransaction,
  fetchTransaction,
  fetchWalletTransactions,
  fetchFundOptions,
  fetchFundDetails,
};
