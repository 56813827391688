import { Result, Wallets } from "./schemas";

const joinTestUsers = `mutation joinTesters($joinTestersInput : EmailInput!) {
    joinTesters(input: $joinTestersInput){
        ${Result}
    }
}`;

const instantiateWallet = `mutation instantiateWallet($instantiateWalletInput: UserInput!){
    instantiateWallet (input: $instantiateWalletInput) {
        ${Wallets}
    }
}`;
export { joinTestUsers, instantiateWallet };
