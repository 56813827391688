// const dayjs = require("dayjs");
const dayjs = {};

const setLocale = (locale, setGlobal) => {
  // require(`dayjs/locale/${locale}`);
  if (setGlobal) {
    dayjs.locale(locale);
  }
};

export default {
  dayjs,
  setLocale,
};
